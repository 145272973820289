import * as React from "react"

import { cn } from "@/lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-sm " +
            "border border-input bg-[#38363D] " +
            "px-3 py-2 text-sm ring-offset-background text-white " +
            "file:border-0  file:text-sm file:font-medium placeholder:font-light " +
            "focus-visible:outline-none  " +
            "disabled:cursor-not-allowed disabled:opacity-50 " +
            " h-12 outline-none border-none",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
